<template>
    <div>
      <div>
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="table.data"
          :max-items="table.length"
          :total="table.total"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="
                  p-3
                  border border-solid
                  d-theme-border-grey-light
                  rounded-full
                  d-theme-dark-bg
                  cursor-pointer
                  flex
                  items-center
                  justify-between
                  font-medium
                "
              >
                <span class="mr-2"
                  >{{ this.table.start }} - {{ this.table.end }} of
                  {{ this.table.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
            <div class="btn-group ml-5">
              <vs-button class="ml-4 mt-2" color="primary" icon-pack="feather" icon="icon-prin" @click="handleExport('excel')">Download Excel</vs-button>
  
            </div>
          </template>
          <template slot="thead">
            <!-- <vs-th></vs-th> -->
            <vs-th
              v-for="(head, index) in this.table.heads"
              :key="index"
              :sort-key="head.field"
              ><div :style="'min-width:' + ( head.width > 0 ? head.width : null ) + 'px'">{{ head.title }}</div></vs-th
            >
          </template>
  
          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td
                v-for="(head, index) in table.heads"
                :key="index"
                :data="data[indextr][head.field]"
                >{{ format(head, table.data[indextr][head.field]) }}</vs-td
              >
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="table.totalPage"
          v-model="setPage"
        />
      </div>
    </div>
  </template>
  
  
<script>
  import moment from 'moment'
  
  export default {
      components: {
          
      },
      props: 
    ["progress", "operatingUnit", "territory_ids", "supplier", "docRefType", "labelString", "territories", "filterDate", "filterPostingDate","getDataCount"],
  mounted() {

  },
  data() {
    return {
      table: this.tableDefaultState(),
    };
  },
  watch: {
    watchedProperties: function (v) {
      console.log("watchedProperties=>",v)
      this.getData(); 
    }
  },
  computed: {
    watchedProperties() {
      return `${this.getDataCount}||${this.search}`;
    },
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        heads: [

          { "title": "Sales Office", "field": "sales_office_code", "width": 80 },
          { "title": "Sales Office Desc", "field": "sales_office_desc", "width": 80 },
          { "title": "Vendor Code", "field": "vendor_code", "width": 80 },
          { "title": "Vendor Name", "field": "vendor_name", "width": 140 },
          { "title": "Purchase Org Code", "field": "purchase_org_code", "width": 80 },
          { "title": "Purchase Org Name", "field": "purchase_org_name", "width": 130 },
          { "title": "Debit Note Number", "field": "debit_note_number", "width": 80 },
          { "title": "Doc Type", "field": "doc_type", "width": 80 },
          { "title": "Doc Ref Type", "field": "doc_ref_type", "width": 80 },
          { "title": "Doc Status", "field": "doc_status", "width": 80 },

          { "title": "Doc Ref No", "field": "doc_ref_no", "width": 80 },
          { "title": "Posting Date", "field": "posting_date", "width": 80 },
          { "title": "Notes", "field": "notes", "width": 80 },
          { "title": "Total Amount", "field": "total_amount", format: this.formatPrice, "width": 80 },
          { "title": "Supplier CN Date", "field": "supplier_cn_date", "width": 80},
          { "title": "Promo Period Date From", "field": "promo_period_date_from", "width": 80 },
          { "title": "Promo Period Date To", "field": "promo_period_date_to", "width": 80 },
          { "title": "Product Code", "field": "product_code", "width": 80 },
          { "title": "Product Description", "field": "product_description", "width": 130 },
          { "title": "Gross Value", "field": "gross_value", format: this.formatPrice, "width": 80 },

          { "title": "Price Value", "field": "price_value", format: this.formatPrice, "width": 80 },
          { "title": "Debit Note Due Date", "field": "debit_note_due_date","width": 80 },
          { "title": "Assignment", "field": "assignment", "width": 80 },
          { "title": "Reference Desc", "field": "reference_desc", "width": 80 },
          { "title": "Sum Total due Current", "field": "sum_total_due_current", format: this.formatPrice, "width": 80},
          { "title": "Due15LC", "field": "due15lc", format: this.formatPrice, "width": 80},
          { "title": "Due30LC", "field": "due30lc", format: this.formatPrice, "width": 80 },
          { "title": "Due60LC", "field": "due60lc", format: this.formatPrice, "width": 80 },
          { "title": "Due90LC", "field": "due90lc", format: this.formatPrice, "width": 80 },
          { "title": "Due180LC", "field": "due180lc", format: this.formatPrice, "width": 80 },

          { "title": "Due365LC", "field": "due365lc", format: this.formatPrice, "width": 80 },
          { "title": "Due730LC", "field": "due730lc", format: this.formatPrice, "width": 80 },
          { "title": "Due More Than730LC", "field": "due_more_than730lc", format: this.formatPrice, "width": 80 },
          { "title": "Total Due", "field": "total_due", format: this.formatPrice, "width": 80 },
          { "title": "Created Date", "field": "created_at", "width": 80},

        ],
      };
    },
    async downloadExcel() {
      if (this.recordsTotal <= 0) {
        this.$vs.notify({
          color: "warning",
          title: "Data not found",
          text: "Can't generating data",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });

        return true;
      }

      this.$vs.notify({
        color: "primary",
        title: "Processing . . . .",
        text: "Generating " + this.recordsTotal + " data",
        position: "top-right",
        iconPack: "feather",
        icon: "icon-x-circle"
      });

      var parameter = {
        territories: this.territories,
        supplier_id: this.supplier,
        doc_ref_type_id: this.docRefType,
        search: this.search,
        territory_ids: this.territory_ids,
        title: "debit_note_report_" + this.supplier + "_" + this.filterDate.startDate != null ? moment(this.filterDate.startDate).format("YYYY-MM-DD") : null + "_-_" + this.filterDate.endDate != null ? moment(this.filterDate.endDate).format("YYYY-MM-DD") : null,
        limit: 100000,
        offset: 0, 
        file: "excel",
        start_date: this.filterDate.startDate != null ? moment(this.filterDate.startDate).format("YYYY-MM-DD") : null,
        end_date: this.filterDate.endDate != null ? moment(this.filterDate.endDate).format("YYYY-MM-DD") : null,
        start_posting_date: this.filterPostingDate.startDate != null ? moment(this.filterPostingDate.startDate).format("YYYY-MM-DD") : null,
        end_posting_date: this.filterPostingDate.endDate != null ? moment(this.filterPostingDate.endDate).format("YYYY-MM-DD") : null,
      };


      this.$http.get("/api/v1/report/debit-note/export", {
        params: parameter,
        // responseType: "arraybuffer",
        // headers: { "Accept": "application/vnd.ms-excel" },
      }).then(resp => {
        // let dateNow = new Date();
        // let blob = new Blob([resp], { type: "application/csv" })

        // const link = document.createElement("a");
        // link.href = window.URL.createObjectURL(blob);
        // link.download = this.dateFormat(dateNow) + "debit-note-claim.xlsx";
        // link.click();

        this.$vs.loading.close();
        if (resp.status == "error") {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: resp.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        } else {
          this.$vs.notify({
            color: "success",
            title: "Processing",
            text: resp.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        }
      });
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      console.log("checkpoint 2")
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      console.log("checkpoint 3")
      if(this.getDataCount>0){
        this.getData();
      }
    },
    handleSort(key, active) {
      if (key == "product_code" || key == "gross_value" || key == "sum_total_due_current" || key == "total_due" || key == "product_description") {
        switch (key) {
          case "product_code":
            this.table.order = "debit_note_lines.item_unit_id";
            break;
          case "product_description":
            this.table.order = "debit_note_lines.item_name";
            break;
          case "gross_value":
            this.table.order = "debit_notes.dn_value";
            break;
          case "total_due":
            this.table.order = "debit_notes.dn_value";
            break;
          case "sum_total_due_current":
            this.table.order = "debit_notes.dn_value";
            break;
          default:
            this.table.order = key;
            break;
        }
        this.table.sort = active;
        console.log("checkpoint 4")
        this.getData();
      }
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      console.log("checkpoint 5")
      this.getData();
    },
    handleClose() {
      this.getData();
      this.$emit("close");
    },

    getData(page = 1) {
      if (this.territories == null || this.territories == "" || this.territories == [] || this.territories == undefined) {
        return true;
      }
      this.isActive = page;
      this.$vs.loading();

      var parameter = {
        length: this.table.length,
        page: this.table.page,
        search: this.table.search,
        order: this.table.order,
        sort: this.table.sort,
        territories: this.territories,
        territory_ids: this.territory_ids,
        supplier_id: this.supplier,
        doc_ref_type_id: this.docRefType,
        start_date: this.filterDate.startDate != null ? moment(this.filterDate.startDate).format("YYYY-MM-DD") : null,
        end_date: this.filterDate.endDate != null ? moment(this.filterDate.endDate).format("YYYY-MM-DD") : null,
        start_posting_date: this.filterPostingDate.startDate != null ? moment(this.filterPostingDate.startDate).format("YYYY-MM-DD") : null,
        end_posting_date: this.filterPostingDate.endDate != null ? moment(this.filterPostingDate.endDate).format("YYYY-MM-DD") : null,
      };

      this.$http.get("/api/v1/report/debit-note", {
        params: parameter
      }).then(resp => {
        if (resp.code == 200) {

          this.table.total = resp.data.total_record;
          this.table.totalPage = resp.data.total_page;
          this.table.totalSearch = resp.data.total_record_search;
          this.table.length = resp.data.total_record_per_page;
          this.table.data = resp.data.records;
          this.setStartEnd();
        } else {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: resp.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
        }
        this.$vs.loading.close();
      });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    dateFormat(val) {
      return moment(val).format("DD MMM YYYY");
    },
    handleExport(file) {
      this.$vs.loading();
      var fileTitle =
        "debit_note" +
        "_" +
        moment(this.startDate).format("YYYY-MM-DD") +
        "_-_" +
        moment(this.endDate).format("YYYY-MM-DD");

      this.$http
        .get("/api/v1/report/debit-note/export", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            territories: this.territories,
            supplier_id: this.supplier,
            doc_ref_type_id: this.docRefType,
            territory_ids: this.territory_ids,
            title: fileTitle,
            file: file,
            start_date: this.filterDate.startDate != null ? moment(this.filterDate.startDate).format("YYYY-MM-DD") : null,
            end_date: this.filterDate.endDate != null ? moment(this.filterDate.endDate).format("YYYY-MM-DD") : null,
            start_posting_date: this.filterPostingDate.startDate != null ? moment(this.filterPostingDate.startDate).format("YYYY-MM-DD") : null,
            end_posting_date: this.filterPostingDate.endDate != null ? moment(this.filterPostingDate.endDate).format("YYYY-MM-DD") : null,
          },
          // responseType: "arraybuffer",
          // headers: {
          //   Accept: "application/octet-stream",
          // },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "success",
              title: "Processing",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        });
    },
    formatPrice(val) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    format(head, value) {
      if (typeof head["format"] === "function") {
        var f = head["format"];
        return f(value);
      }
      return value;
    },
  }
};
</script>
  
<style>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
